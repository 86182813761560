var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('card-header',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_vm._v("Schriftverkehr")]),_c('app-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"pagination":_vm.pagination,"loading":_vm.loading,"hide-default-footer":"","no-data-text":_vm.$t('message.letter.notLoaded'),"no-results-text":"Keine Briefe gefunden"},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:_vm.itemClass(item.state)},[_c('td',[_vm._v(_vm._s(_vm._f("date")(item.created)))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(_vm._s(item.receiver.name))]),_c('td',[_vm._v(_vm._s(item.creator.name))]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('td',[(item.state !== 'send')?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){_vm.mailLetter = item}}},[_c('v-icon',{class:_vm.btnClass(item.state)},[_vm._v("mail")])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){_vm.mailLetter = item}}},on),[_c('v-icon',{class:_vm.btnClass(item.state)},[_vm._v("mail_outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Versendet am "+_vm._s(_vm._f("date")(item.mailed)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){_vm.preview = item}}},on),[_c('v-icon',{class:_vm.btnClass(item.state)},[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v("Briefvorschau")])]),_c('download-action',{attrs:{"uuid":item.id,"createdDate":item.updated || item.created,"description":item.description,"receiver":item.receiver.name,"color":_vm.btnClass(item.state)}}),_c('v-menu',{attrs:{"bottom":"","left":"","disabled":item.state === 'send'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","disabled":item.state === 'send'}},on),[_c('v-icon',{class:_vm.btnClass(item.state)},[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.updateLetter = item}}},[_c('v-list-item-title',[_vm._v("Brief bearbeiten")])],1),_c('v-list-item',{on:{"click":function($event){_vm.deleteLetter = item}}},[_c('v-list-item-title',[_vm._v("Brief löschen")])],1)],1)],1)],1):_vm._e()])]}}])})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('letter-build-dialog'):_vm._e(),_c('update-letter-dialog',{attrs:{"letter":_vm.updateLetter},on:{"update:letter":function($event){_vm.updateLetter=$event}}}),_c('delete-letter-dialog',{attrs:{"letter":_vm.deleteLetter},on:{"update:letter":function($event){_vm.deleteLetter=$event}}}),_c('mail-letter-dialog',{attrs:{"letter":_vm.mailLetter},on:{"update:letter":function($event){_vm.mailLetter=$event}}}),_c('letter-preview-dialog',{attrs:{"letter":_vm.preview},on:{"update:letter":function($event){_vm.preview=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }