















































































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { PaginatableMixin } from '@/views/mixin';
import { Letter, LetterList, State } from '@/modules/correspondence/types';
import { ApiResponse, Pagination } from '@/components/types';
import { FETCH_LETTER_LIST, SET_LETTER_PAGE } from '../store';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import DownloadAction from './DownloadAction.vue';
import LetterBuildDialog from './LetterBuildDialog.vue';
import UpdateLetterDialog from './UpdateLetterDialog.vue';
import LetterPreviewDialog from './LetterPreviewDialog.vue';
import MailLetterDialog from './MailLetterDialog.vue';
import DeleteLetterDialog from './DeleteLetterDialog.vue';
import AppTable from '@/components/AppTable.vue';
import CardHeader from '@/components/CardHeader.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    CardHeader,
    AppTable,
    DeleteLetterDialog,
    MailLetterDialog,
    LetterPreviewDialog,
    UpdateLetterDialog,
    LetterBuildDialog,
    ListSearchDialog,
    DownloadAction
  }
})
export default class List extends PaginatableMixin {
  @Prop({ type: String }) conservatorship?: string;

  @Correspondence.State('letters') list!: LetterList;
  @Correspondence.Action(FETCH_LETTER_LIST) fetch!: () => Promise<ApiResponse>;
  @Correspondence.Mutation(SET_LETTER_PAGE) setPage!: (page: number) => void;

  loading: boolean = false;
  error: any = null;

  updateLetter: Letter | null = null;
  deleteLetter: Letter | null = null;
  mailLetter: Letter | null = null;
  preview: Letter | null = null;

  pagination: Pagination = {
    sortBy: ['created'],
    itemsPerPage: -1,
    page: 1,
    descending: true
  };

  get items(): Letter[] {
    return this.list.items
      .filter((letter) => !this.conservatorship || (letter.conservatorship && letter.conservatorship.id === this.conservatorship))
      .map((letter) => ({ ...letter, createdTrans: format(letter.created, 'dd.MM.yyyy') }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.created'), value: 'created' },
      { align: 'left', text: this.$i18n.t('common.label'), value: 'description' },
      { align: 'left', text: this.$i18n.t('common.receiver'), value: 'receiver.name' },
      { align: 'left', text: this.$i18n.t('common.creator'), value: null, sortable: false },
      ...(this.$vuetify.breakpoint.mdAndUp ? [{
        align: 'left',
        text: this.$i18n.t('common.actions'),
        value: null
      }] : [])
    ];
  }

  itemClass(state: State): string {
    if (state === 'mailed') {
      return 'success';
    }

    return '';
  }

  btnClass(state: State): string {
    if (state === 'mailed') {
      return 'btn-success';
    }

    return 'btn-info';
  }

  async created() {
    try {
      this.loading = true;

      await this.fetch();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
